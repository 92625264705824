<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pr-0 pt-4 pb-0">
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0">
          <v-container>
            <v-row class="pr-4">
              <v-col cols="12" md="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-model="isFormValid"
                v-show="showFilters"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Conceptos filtro -->
                  <v-col cols="12" md="4" class="pt-0 px-1">
                    <v-autocomplete
                      v-model="conceptoSelected"
                      return-object
                      :items="conceptos"
                      label="Concepto"
                      item-text="value"
                      hide-details="auto"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Periodo filtro -->
                  <v-col cols="12" md="2" class="pt-0 px-1">
                    <v-text-field
                      v-model="periodoFilter"
                      label="Período"
                      outlined
                      :rules="rules.periodoYyyyMm"
                      dense
                      v-mask="'######'"
                      hide-details="auto"
                      hint="Formato AAAAMM"
                    ></v-text-field>
                  </v-col>
                  <!-- Porcentaje de aumento -->
                  <v-col cols="12" md="2" class="pt-0 px-1">
                    <v-text-field
                      v-model="valorAumento"
                      label="Porcentaje"
                      outlined
                      hide-details="auto"
                      dense
                      :rules="
                        rules.required.concat(
                          rules.nonZero,
                          rules.maxDigitsDecimal(4, 2)
                        )
                      "
                      :appendIcon="porcentajeIcon"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Redondeo -->
                  <v-col cols="12" md="3" class="pt-0 px-1">
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      v-model="redondeoSelect"
                      hide-details="auto"
                      :items="redondeoItems"
                      item-text="value"
                      item-value="id"
                      :rules="rules.required"
                      label="Redondeo"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="py-0 px-1">
                    <v-autocomplete
                      v-model="convenioSelected"
                      :items="convenios"
                      label="Convenio"
                      return-object
                      item-text="value"
                      hide-details="auto"
                      item-value="id"
                      @change="setPlanesByConvenios()"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="py-0 px-1">
                    <v-autocomplete
                      v-model="planSelected"
                      :loading="loadingPlanesByConvenio"
                      :items="planes"
                      hide-details="auto"
                      label="Plan"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="py-0 px-1">
                    <v-autocomplete
                      label="Agrup. geográfica"
                      :items="agrupacionesGeografica"
                      v-model="agrupGeograficaSelected"
                      return-object
                      @change="setEstructuras()"
                      hide-details="auto"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="py-0 px-1">
                    <v-autocomplete
                      v-model="grupoLocalidadSelected"
                      :items="gruposLocalidades"
                      label="Grupo de localidades"
                      item-text="value"
                      hide-details="auto"
                      item-value="id"
                      outlined
                      :rules="
                        agrupGeograficaSelected != null ? rules.required : []
                      "
                      clearable
                      dense
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pr-1 pl-4 pt-2" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <!-- Seccion de tabla con los procesos -->
        <v-card>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                v-model="registrosSeleccionados"
                :headers="headers"
                :items="procesosItems"
                :loading="loading"
                :search="search"
                item-key="adicionalId"
                show-select
                @toggle-select-all="toggleSelectedItems()"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template
                  v-slot:[`item.data-table-select`]="{
                    item,
                    isSelected,
                    select
                  }"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-simple-checkbox
                        :value="isSelected"
                        :readonly="item.registroDuplicado"
                        :disabled="item.registroDuplicado"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @input="select($event)"
                        :ripple="false"
                      ></v-simple-checkbox>
                    </template>
                    <span v-if="item.registroDuplicado"
                      >Registro duplicado. No se puede seleccionar</span
                    >
                    <span v-else>Seleccionar registro</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.benGrId`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.benGrId }}</span
                  >
                </template>
                <template v-slot:[`item.afiliadoTit`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.afiliadoTit }}</span
                  >
                </template>
                <template v-slot:[`item.concepto.value`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.concepto.value }}</span
                  >
                </template>
                <template v-slot:[`item.periodoDesde`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.periodoDesde }}</span
                  >
                </template>
                <template v-slot:[`item.periodoHasta`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.periodoHasta }}</span
                  >
                </template>
                <template v-slot:[`item.importeToShow`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.importeToShow }}</span
                  >
                </template>
                <template v-slot:[`item.nuevoImporteToShow`]="{ item }">
                  <span :style="item.registroDuplicado ? 'color:red' : ''">
                    {{ item.nuevoImporteToShow }}</span
                  >
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left v-if="canEdit">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        :disabled="item.registroDuplicado"
                        @click="openModalEditImporteFijo(item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar importe nuevo</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="10">
            <span
              v-if="procesosItems.find(x => x.registroDuplicado)"
              style="color: red"
            >
              *Existen grupos familiares con dos o más titulares.
            </span>
          </v-col>
        </v-row>
        <!-- Seccion de datos para el aumento en % -->
        <v-card class="mt-2">
          <v-form
            v-model="actualizacionValida"
            ref="actualizacion-form"
            id="actualizacion-form"
            @submit.prevent="nuevoProcesoActualizacion()"
          >
            <v-card :loading="loadingProceso">
              <template slot="progress">
                <v-progress-linear
                  color="primary"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card-title class="primary--text pb-0">
                    Datos para el aumento</v-card-title
                  >
                </v-col>
                <!-- Periodo desde -->
                <v-col cols="12" md="2" class="pl-8 pb-0 pt-7">
                  <v-text-field
                    v-model="periodoDesde"
                    label="Período desde"
                    outlined
                    dense
                    v-mask="'######'"
                    hint="Formato AAAAMM"
                    :rules="
                      rules.required.concat(
                        [rules.validDateRange(periodoDesde, periodoHasta)],
                        rules.periodoYyyyMm
                      )
                    "
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <!-- Periodo hasta -->
                <v-col cols="12" md="2" class="pb-0 pt-7">
                  <v-text-field
                    v-model="periodoHasta"
                    label="Período hasta"
                    outlined
                    dense
                    v-mask="'######'"
                    hint="Formato AAAAMM"
                    :rules="
                      rules.required.concat(
                        [rules.validDateRange(periodoDesde, periodoHasta)],
                        rules.periodoYyyyMm
                      )
                    "
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-2 pr-8 text-right">
                  <v-btn
                    color="primary"
                    :disabled="!actualizacionValida || loadingProceso"
                    elevation="2"
                    type="submit"
                    form="actualizacion-form"
                  >
                    Procesar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openEditImporteFijo"
      v-model="openEditImporteFijo"
      max-width="400px"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditImporteNvoProcActualizacionImpFijos
        :importeFijoToEdit="itemToEdit"
        @closeAndUpdate="closeAndUpdate"
        @closeAndReload="closeAndReload"
      ></EditImporteNvoProcActualizacionImpFijos>
    </v-dialog>
  </v-container>
</template>
<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditImporteNvoProcActualizacionImpFijos from "@/components/modules/cuotas/devengamientos/EditImporteNvoProcActualizacionImpFijos.vue";

export default {
  name: "NuevoProcesoActualizacionImportesFijos",
  components: {
    GoBackBtn,
    PageHeader,
    FiltersSelected,
    EditImporteNvoProcActualizacionImpFijos
  },
  directives: { mask },
  data: () => ({
    rules: rules,
    routeToGo: "ProcesoActualizacionImportesFijos",
    title: enums.titles.NUEVA_ACTUALIZACION_IMPORTES_FIJOS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    searchIcon: enums.icons.SEARCH,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    editIcon: enums.icons.EDIT,
    search: "",
    showFilters: true,
    loading: false,
    loadingProceso: false,
    canEdit: true,
    actualizacionValida: false,
    periodoDesde: null,
    periodoHasta: null,
    valorAumento: null,
    periodoFilter: null,
    redondeoSelect: null,
    redondeoItems: [
      { id: 1, value: "Sin redondeo", refNum: 0 },
      { id: 2, value: "Unidad", refNum: 1 },
      { id: 3, value: "Decena", refNum: 10 }
    ],
    filtersApplied: [],
    conceptos: [],
    conceptoSelected: null,
    registrosSeleccionados: [],
    isFormValid: true,
    openEditImporteFijo: false,
    itemToEdit: null,
    procesosItems: [],
    headers: [
      {
        text: "N° de grupo familiar",
        value: "benGrId",
        align: "start",
        sortable: false
      },
      {
        text: "Afiliado titular",
        value: "afiliadoTit",
        align: "start",
        sortable: false
      },
      {
        text: "Concepto",
        value: "concepto.value",
        align: "start",
        sortable: false
      },
      {
        text: "Período desde",
        value: "periodoDesde",
        align: "start",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoHasta",
        align: "start",
        sortable: false
      },
      {
        text: "Importe anterior",
        value: "importeToShow",
        align: "end",
        sortable: false
      },
      {
        text: "Importe nuevo",
        value: "nuevoImporteToShow",
        align: "end",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end"
      }
    ],
    disabledCount: 0,
    agrupacionesGeografica: [],
    agrupGeograficaSelected: null,
    gruposLocalidades: [],
    grupoLocalidadSelected: null,
    convenios: [],
    planes: [],
    convenioSelected: null,
    planSelected: null,
    loadingPlanesByConvenio: false
  }),
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.redondeoSelect = this.redondeoItems[0];
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getConceptosAdicionales: "devengamientos/getConceptosAdicionales",
      getImportesFijosAndUpdateImporte:
        "devengamientos/getImportesFijosAndUpdateImporte",
      saveProcesoActualizacionImportesFijos:
        "devengamientos/saveProcesoActualizacionImportesFijos",
      getAgrupacionGeografica: "analytics/getAgrupacionGeografica",
      getEstructurasByAgruId: "proveedores/getEstructurasByAgruId",
      getConvenios: "afiliaciones/getConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const response = await this.getConceptosAdicionales();
      this.conceptos = response;
      const agrupacionesGeograficas = await this.getAgrupacionGeografica();
      this.agrupacionesGeografica = agrupacionesGeograficas;
      const convenios = await this.getConvenios();
      this.convenios = convenios;
    },
    async setPlanesByConvenios() {
      this.planes = [];
      this.planSelected = null;
      if (this.convenioSelected != null) {
        this.loadingPlanesByConvenio = true;
        const planes = await this.getPlanesByConvenio([
          this.convenioSelected.id
        ]);
        this.planes = planes;
        this.loadingPlanesByConvenio = false;
      }
    },
    async setEstructuras() {
      this.gruposLocalidades = [];
      this.grupoLocalidadSelected = null;
      if (this.agrupGeograficaSelected != null) {
        const estructuras = await this.getEstructurasByAgruId(
          this.agrupGeograficaSelected?.id
        );
        this.gruposLocalidades = estructuras;
      } else this.gruposLocalidades = [];
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async applyFilters() {
      this.registrosSeleccionados = [];
      this.disabledCount = 0;
      this.loading = true;
      const filters = {
        periodo: this.periodoFilter,
        conceptoId:
          this.conceptoSelected != null ? this.conceptoSelected.id : null,
        porcentajeActualizacion: this.valorAumento,
        redondeo: this.redondeoSelect.refNum,
        grupoLocId:
          this.grupoLocalidadSelected != null
            ? this.grupoLocalidadSelected.id
            : null,
        convenioId:
          this.convenioSelected != null ? this.convenioSelected.id : null,
        planId: this.planSelected != null ? this.planSelected.id : null
      };
      try {
        const response = await this.getImportesFijosAndUpdateImporte(filters);
        this.customizeFiltersApplied();
        this.showFilters = false;
        this.procesosItems = response;
        this.procesosItems.map(item => {
          if (item.registroDuplicado) this.disabledCount += 1;
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.conceptoSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "conceptoSelected",
          label: "Concepto",
          model: this.conceptoSelected.value
        });
      }
      if (this.periodoFilter) {
        this.filtersApplied.splice(1, 0, {
          key: "periodoFilter",
          label: "Período",
          model: this.periodoFilter
        });
      }
      if (this.valorAumento) {
        this.filtersApplied.splice(2, 0, {
          key: "valorAumento",
          label: "Porcentaje actualización",
          model: this.valorAumento.toString() + "%"
        });
      }
      if (this.redondeoSelect) {
        this.filtersApplied.splice(3, 0, {
          key: "redondeoSelect",
          label: "Redondeo",
          model: this.redondeoSelect.value
        });
      }
      if (this.convenioSelected) {
        this.filtersApplied.splice(4, 0, {
          key: "convenioSelected",
          label: "Convenio",
          model: this.convenioSelected.value
        });
      }
      if (this.planSelected) {
        this.filtersApplied.splice(5, 0, {
          key: "planSelected",
          label: "Plan",
          model: this.planSelected.value
        });
      }
      if (this.agrupGeograficaSelected) {
        this.filtersApplied.splice(6, 0, {
          key: "agrupGeograficaSelected",
          label: "Agrup. geográfica",
          model: this.agrupGeograficaSelected.value
        });
      }
      if (this.grupoLocalidadSelected) {
        this.filtersApplied.splice(7, 0, {
          key: "grupoLocalidadSelected",
          label: "Grupo de localidades",
          model: this.grupoLocalidadSelected.value
        });
      }
    },
    async nuevoProcesoActualizacion() {
      if (!this.registrosSeleccionados.length > 0) {
        this.setAlert({
          type: "warning",
          message:
            "Debe seleccionar al menos un importe de la tabla para actualizar"
        });
      } else {
        this.loadingProceso = true;
        const data = {
          importesFijos: this.registrosSeleccionados,
          nuevoPeriodoDesde: this.periodoDesde,
          nuevoPeriodoHasta: this.periodoHasta,
          filtrosAplicados: `Concepto: ${this.conceptoSelected?.value ??
            "Sin dato."}; Período: ${this.periodoFilter ??
            "Sin dato."}; Porcentaje: ${this.valorAumento ??
            "Sin dato."}; Redondeo: ${this.redondeoSelect?.value ??
            "Sin dato."}; Convenio: ${this.convenioSelected?.value ??
            "Sin dato."}; Plan: ${this.planSelected?.value ??
            "Sin dato."}; Agrup. geográfica: ${this.agrupGeograficaSelected
            ?.value ?? "Sin dato."}; Grupo de localidades: ${this
            .grupoLocalidadSelected?.value ?? "Sin dato."};`
        };
        try {
          const response = await this.saveProcesoActualizacionImportesFijos(
            data
          );
          if (response.status === 200)
            await this.setAlert({
              type: "success",
              message: "Guardado con éxito"
            });
          this.loadingProceso = false;
          this.backToProcesoActualizacion();
        } catch (error) {
          this.loadingProceso = false;
        }
      }
    },
    openModalEditImporteFijo(item) {
      this.itemToEdit = item;
      this.openEditImporteFijo = true;
    },
    closeAndReload() {
      this.openEditImporteFijo = false;
    },
    closeAndUpdate(data) {
      this.openEditImporteFijo = false;
      for (let i = 0; i < this.procesosItems.length; i++) {
        if (
          this.procesosItems[i].adicionalId === data.idProceso &&
          this.procesosItems[i].nuevoImporteToEdit == data.importeViejo
        ) {
          this.procesosItems[i].nuevoImporteToEdit = data.importeNuevo;
          this.procesosItems[i].nuevoImporteToShow = data.importeNuevo;
          break;
        }
      }
      this.setAlert({
        type: "success",
        message: "Actualizado importe con éxito"
      });
    },
    backToProcesoActualizacion() {
      this.$router.push({ name: "ProcesoActualizacionImportesFijos" });
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    toggleSelectedItems() {
      if (
        this.registrosSeleccionados.length !=
        this.procesosItems.length - this.disabledCount
      ) {
        this.registrosSeleccionados = [];
        this.procesosItems.forEach(item => {
          if (!item.registroDuplicado) this.registrosSeleccionados.push(item);
        });
        //Veo si hay procesos duplicados para ver que alerta muestro
        const duplicados = this.procesosItems.some(
          el => el.registroDuplicado == true
        );
        if (duplicados == true) {
          this.setAlert({
            type: "info",
            message:
              "Todos los registros que no están duplicados, han sido seleccionados."
          });
        } else {
          this.setAlert({
            type: "info",
            message: "Todos los registros han sido seleccionados."
          });
        }
      } else this.registrosSeleccionados = [];
    }
  }
};
</script>
